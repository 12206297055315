import { createSelector } from '@reduxjs/toolkit';
import qs from 'querystring';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectPathname } from 'src/redux/navigation/selectors';
import {
  selectNextPageNumber,
  selectPreviousPageNumber,
  selectRequestOptionsAsQueryObject,
} from 'src/redux/products/selectors';

const selectProducts = ({ products }: ReduxApplicationState) => products;

export const selectLoadMoreProductsURL = createSelector(
  selectRequestOptionsAsQueryObject,
  selectNextPageNumber,
  (selectedSortAndFilterOptionsQuery, nextPage) =>
    `?${qs.stringify({
      ...selectedSortAndFilterOptionsQuery,
      page: nextPage,
    })}`,
);

export const selectLoadPreviousProductsURL = createSelector(
  selectRequestOptionsAsQueryObject,
  selectPreviousPageNumber,
  selectPathname,
  (selectedSortAndFilterOptionsQuery, previousPage, pathname) => {
    const queryString = `${qs.stringify({
      ...selectedSortAndFilterOptionsQuery,
      ...(previousPage > 1 && { page: previousPage }),
    })}`;

    return queryString.length > 0 ? `${pathname}?${queryString}` : pathname;
  },
);

const selectProductItems = ({ products: { items } }: ReduxApplicationState) => items;

export const selectNavigatedFromOrToPageOne = createSelector([selectProductItems], (items) =>
  Object.keys(items).includes('1'),
);

export const selectLandedOnPageOne = createSelector(
  selectProducts,
  (products) => products.initialProductPage === 1,
);

export const selectRedirectedFromSearch = ({
  router: {
    location: { search },
  },
}: ReduxApplicationState) => !!new URLSearchParams(search).get('categoryRedirect');
